<template>
  <div class="hero">
    <div class="container">
      <div class="row">
        <div class="col-sm-6">
          <h1>{{content.hero.title}}</h1>
          <section v-html="content.hero.txt"></section>
        </div>
        <div class="col-sm-6">
          <img class="uni-logo" src="@/assets/img/unitech_logo_construction.png">
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row competition">
      <div class="col-sm-6">
        <h2>{{content.competition.title}}</h2>
        <div v-html="content.competition.txt"></div>
        <router-link :to="{name:'Page_269_'+language}" class="btn btn-primary">{{content.competition.btn}}</router-link>
      </div>
      <div class="col-sm-6">
        <img class="side-img" src="@/assets/img/about_the_competition.jpg">
      </div>
    </div>
  </div>
  <div class="prices">
    <div class="container prices-container">
      <h2>{{content.awards.title}}</h2>
      <div class="row">
        <div class="col-lg-7 mini-container">
          <img class="claas-hq-img" src="@/assets/img/claas_hq.jpg" alt="A németországi CLAAS központ">
        </div>
        <div class="col-lg-5 mini-container">
          <div class="primary">
            <span>
              <img class="icon" src="@/assets/img/icons/airplanemode_active.svg" alt="">
            </span>
            <p><b>{{content.awards.title1}}</b>
              <br>{{content.awards.txt1}}</p>
          </div>
        </div>
      </div>
      <div class="row other-prices-container">
        <div class="col-sm other-prices">
          <span>
            <img class="icon" src="@/assets/img/icons/credit_card.svg" alt="">
          </span>
          <p><b>{{content.awards.title2}}</b><br>{{content.awards.txt2}}</p>
        </div>
        <div class="col-sm other-prices">
          <span>
            <img class="icon" src="@/assets/img/icons/credit_card.svg" alt="">
          </span>
          <p><b>{{content.awards.title3}}</b><br>{{content.awards.txt3}}</p>
        </div>
      </div>
      <div class="row other-prices-container">
        <div class="col-sm other-prices">
          <span>
            <img class="icon" src="@/assets/img/icons/credit_card.svg" alt="">
          </span>
          <p><b>{{content.awards.title4}}</b><br>{{content.awards.txt4}}</p>
        </div>
        <div class="col-sm other-prices">
          <span>
            <img class="icon" src="@/assets/img/icons/credit_card.svg" alt="">
          </span>
          <p><b>{{content.awards.title5}}</b><br>{{content.awards.txt5}}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="container timing-container">
    <div class="row">
      <h2>{{content.timing.title}}</h2>
      <div class="apply">
        <span></span>
        <p>{{content.timing.txt1}}<br><b>{{content.timing.deadline1}}</b></p>
      </div>
      <div class="test">
        <span></span>
        <p>{{content.timing.txt2}}<br><b>{{content.timing.deadline2}}</b></p>
      </div>
      <div class="online">
        <span></span>
        <p>{{content.timing.txt3}}<br><b>{{content.timing.deadline3}}</b></p>
      </div>
      <div class="final">
        <span></span>
        <p>{{content.timing.txt4}}<br><b>{{content.timing.deadline4}}</b></p>
      </div>
    </div>
  </div>
  <div class="prices">
    <div class="container claas-container">
      <div class="row claas">
        <div class="col-sm-6">
          <img class="side-img" src="@/assets/img/cockpit.jpg">
        </div>
        <div class="col-sm-6">
          <h2>{{content.claas.title}}</h2>
          <div v-html="content.claas.txt"></div>
          <router-link :to="{name:'Page_268_'+language}" class="btn btn-primary">{{content.claas.btn}}</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'MainPage',
  props:['initData','contents','pageId'],
  data(){
    return{}
  },
  computed:{
    language(){
      return this.initData.init.language
    },
    content(){
      let contents = this.contents[this.pageId]

      this.initData.init.head.pageId = this.pageId
      this.initData.init.head.title = contents.head.title
      this.initData.init.head.keywords = contents.head.keywords
      this.initData.init.head.description = contents.head.description

      return contents
    }
  }
}
</script>
<style scoped>

.container {
  max-width: 1064px;
  padding: 9.5rem 2rem;
}

/*TODO ARTÚR JAVÍTJA*/
div {
  padding: 0;
  margin: 0 auto;
}

.mini-container:nth-of-type(1) {
  padding-right: 0;
}

.mini-container:nth-of-type(2) {
  padding-left: 0;
}

.other-prices-container {
  max-width: 1064px;
}

button{
  border-radius: 0;
  padding: 0.5rem 2rem;
  font-size: 0.875rem;
}

h2 {
  font-size: 2.5rem;
  line-height: 3rem;
  color: var(--black);
  margin-bottom: 1.5rem;
  font-weight: 300;
}

p {
  max-width: 435px;
}
/*TODO ARTÚR JAVÍTJA -> EMIATT PL*/
.competition div{
  max-width: 435px;
}

img {
  width: 100%;
  height: 100%;
}

span {
  height: 100px;
  width: 100px;
  background-color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero {
  background-image: url("@/assets/img/hero_background.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 600px;
  display: flex;
}

.hero>div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hero>div div:nth-child(2) {
  text-align: right;
}

.hero p,.hero h1,.hero section{
  color: var(--white);
  font-weight: lighter;
}

.uni-logo {
  width: 273px;
  height: 212px;
}

.side-img {
  height: 400px;
  width: 100%;
  object-fit: cover;
}

.competition p {
  line-height: 1.5rem;
}

.prices, .claas-container {
  width: 100%;
  background-color: var(--gray);
}

.competition, .claas{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.claas .col-sm-6:nth-child(2){
  padding-left: 4rem;
  padding-right: 0;
}

.claas-hq-img {
  width: 100%;
  object-fit: cover;
}

.prices-container h2 {
  margin-bottom: 3rem;
}

.prices p {
  font-weight: 300;
}

.other-prices {
  display: flex;
  align-items: center;
  justify-content: left;
  margin-top: 4rem;
  padding: 0;
}

.icon {
  width: 40px;
  height: 40px;
}

.primary {
  background-color: var(--class-green);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  padding: 4rem;
  height: 100%;
}

.primary span {
  margin-bottom: 2rem;
}

.primary p {
  margin: 0;
}

.prices-container p, .other-prices p {
  font-size: 1.5rem;
}

.other-prices p {
  margin: 0 0 0 1rem;
}

div.other-prices div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.timing-container p {
  font-size: 1.5rem;
  padding-left: 3rem;
}

.timing-container b {
  font-size: 2.5rem;
}

.timing-container b, .prices b {
  font-weight: 700;
}

.apply span {
  background-color: var(--class-green);
}

.test span {
  background-color: var(--gray);
}

.online span {
  background-color: var(--gray);
}

.final span {
  background-color: var(--red);
}

.timing-container div {
  display: flex;
  justify-content: left;
  align-items: center;
  margin: 1rem 0;
}

.timing-container span {
  width: 1rem;
  height: 11rem;
  display: block;
}

@media (max-width: 375px) {
  .hero img {
    width: 50%;
    height: 50%;
  }
}

@media (max-width: 576px) {
  .claas .col-sm-6:nth-child(2){
    padding: 1rem;
    margin-top: 2rem;
  }

  .hero div:nth-child(2){
    display: flex;
    justify-content: center;
    align-self: center;
  }

  .competition div .btn {
    margin-bottom: 1rem;
  }

  button{
    border-radius: 0;
    padding: 0.5rem 2rem;
    font-size: 0.875rem;
    margin-bottom: 2rem;
  }
}
</style>
